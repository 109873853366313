import React from 'react';
import { Link } from 'react-router-dom';

import classes from './styles.module.css';

const HireMe = (props) => {
    
    return (
            <Link to="contact">
                <div className={classes.message}>
                    Hire me!
                </div>
            </Link>
    );
};

export default HireMe;