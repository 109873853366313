export const projects = [
    {
        platform: 'Fitbit',
        title: 'Battery Log',
        icon: '/images/BatteryLog/batteryLog.png',
        icon_title: 'Battery Log',
        icon_code: 'vscode-icons:file-type-fitbit',
        source: 'Fitbit',
        storeurl: 'https://gallery.fitbit.com/details/a94a5217-87da-4c4a-b79f-4d3db9fbad6c',
        giturl: 'https://github.com/ThorgalAegirsson/fitbit-battery-log',
        description: 'App for Fitbit devices that shows the current status of their battery, its charge level and information when it was charged last. There is also a paid Pro version of the app with more features and support for more devices',
        images: [
            {
                src: '/images/BatteryLog/Screenshots/01.png',
                alt: 'Fitbit Battery Log'
            },
            {
                src: '/images/BatteryLog/Screenshots/02.png',
                alt: 'Fitbit Battery Log'
            },
            {
                src: '/images/BatteryLog/Screenshots/03.png',
                alt: 'Fitbit Battery Log'
            },
            {
                src: '/images/BatteryLog/Screenshots/04.png',
                alt: 'Fitbit Battery Log'
            },
            {
                src: '/images/BatteryLog/Screenshots/05.png',
                alt: 'Fitbit Battery Log'
            }
        ]
    }, {
        platform: 'Windows',
        title: 'Reason for Windows Phone',
        icon: '/images/Reason/reason.png',
        icon_title: 'Reason-for-Windows-Phone-8.1',
        icon_code: 'logos:microsoft-windows',
        source: "Microsoft Store",
        storeurl: 'https://www.microsoft.com/store/apps/9n58mspggxqx?cid=storebadge&ocid=badge',
        giturl: 'https://github.com/ThorgalAegirsson/Reason-for-Windows-Phone-8.1',
        description: 'It\'s a mobile reader for Reason Magazine. It was written for Windows Phone 8 and above',
        images: [
            {
                src: '/images/Reason/Screenshots/01.png',
                alt: 'Reason'
            },
            {
                src: '/images/Reason/Screenshots/02.png',
                alt: 'Reason'
            },
            {
                src: '/images/Reason/Screenshots/03.png',
                alt: 'Reason'
            },
            {
                src: '/images/Reason/Screenshots/04.png',
                alt: 'Reason'
            }
        ]
    }, {
        platform: 'Web',
        title: 'Domain Management System',
        icon: '/images/DMS/dms.png',
        icon_title: 'Domain Management System',
        icon_code: 'logos:microsoft-edge',
        source: '',
        storeurl: 'https://dms.radek.website',
        giturl: 'https://github.com/ThorgalAegirsson/Domain-Management-System.git',
        description: 'The web app for managing domains, hosting, SSL certificates and email addresses. Built with React, utilizing React Context and React Router. The backend has been built on Firebase',
        images: [
            {
                src: '/images/DMS/Screenshots/01.png',
                alt: 'Domain Management System'
            },
            {
                src: '/images/DMS/Screenshots/02.png',
                alt: 'Domain Management System'
            },
            {
                src: '/images/DMS/Screenshots/03.png',
                alt: 'Domain Management System'
            },
            {
                src: '/images/DMS/Screenshots/04.png',
                alt: 'Domain Management System'
            },
            {
                src: '/images/DMS/Screenshots/05.png',
                alt: 'Domain Management System'
            }
        ]
    }
];