import React from 'react';

import Badge from './Badge';
import Image from './Image';

import classes from './styles.module.css';

const AppSample = (props) => {
    const images = props.images || [];
    return (
        <div className={classes.wrapper}>
            <h2>{props.platform}</h2>
            <div className={classes.app}>
                <div className={classes.header}>
                    <img src={props.icon} alt={props.icon_title} />
                    <div className={classes.headerText}>
                        <h3>{props.title}</h3>
                        <Badge icon={props.icon_code} source={props.source} giturl={props.giturl} storeurl={props.storeurl} />
                    </div>
                </div>
                <div className={classes.description}>
                    <p>{props.description}</p>
                </div>
                <div className={classes.images}>
                    {images.map(image=><Image image={image.src} alt={image.alt} />)}
                </div>
            </div>
        </div>
        
    );
};

export default AppSample;