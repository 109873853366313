import React from 'react';
import { Icon } from '@iconify/react';

import classes from './styles.module.css';

const Badge = (props) => {
    const source = props.source ? `Get it from ${props.source}` : 'Open in browser';
    return (
        <div className={classes.wrapper}>

            <a className={classes.link} href={props.storeurl} target='_blank' rel='noreferrer'>
                <Icon icon={props.icon} height="32" inline={true} />
                <p className={classes.text}>{source}</p>
            </a>
            <a className={classes.link} href={props.giturl} target='_blank' rel='noreferrer'>
                <Icon icon="jam:github-circle" height="32" inline={true} />
                <p className={classes.text}>Download source code</p>
            </a>

        </div>
    );
};

export default Badge;