import React from 'react';

import classes from './styles.module.css';

const About = (props) => {

    return (
            <div className={classes.wrapper}>
                <h1>Who am I?</h1>

                <article>
                    <p>
                        First and foremost I'm a husband and a proud father of a beautiful baby girl.
                    </p>
                    <img className={classes.small_pic} src="images/Atari.png" alt="Atari" />
                    <p>
                        I&nbsp;am passionate about science &amp; technology. I fell in love with computers as a kid back in 1986, when I first saw 8-bit Atari XE machines in a video arcade. A year later my parents changed my life forever and bought me my very own Atari 65XE. I remember creating my simple programs in Basic, such as a calendar and a very primitive database. I felt like I can do anything with that machine. I immediately knew that my life would be connected to computers one way or another. Later, life gave me opportunities to experience other paths, such as finance, management, through computer networking back to programming. Coding never actually left me, as I created more or less sophisticated Visual Basic macros in my financial work.
                    </p>
                    <p style={{ clear: 'both' }}>
                        In my spare time (which I don't have anymore 😢) I like to play with Arduino, Raspberry Pi and my drone. In my previous life I used to be a martial arts instructor. I also competed in Karate Kyokushin tournaments.
                    </p>
                    <p>
                        I’m a fast learner, free thinker, independent, curious. My career is very eclectic. I like to try everything and my interests are very broad.
                    </p>
                    <p>
                        These days I develop web apps using React. As a backend I usually use Firebase but I have also tried Node.js, Express and MongoDB.
                        Web technologies also let me create apps for Fitbit and Windows.
                    </p>
                </article>

                {/* 
            <h1>My skills</h1>
            <p>HTML, CSS, JavaScript</p>
            
        <h2>Education?? fCC, Udemy</h2> */}

                <button className={classes.download}><a href="downloads/cv.pdf" target='_blank' rel='noreferrer'>Download my CV</a></button>

                <img src="images/desk.svg" alt="Me on the desk" className={classes.pic} />
            </div>
    );
};

export default About;
