import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { loadReCaptcha } from 'react-recaptcha-google';

import Layout from './components/Layout';
import HomePage from './pages/Home';
import AboutPage from './pages/About';
import ContactPage from './pages/Contact';
import ExperiencePage from './pages/Experience';



function App() {
  // useEffect(() => {
  //   loadReCaptcha();
  //   console.log('recaptcha loaded');
  // }, []);
  return (
    <Layout>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='about' element={<AboutPage />} />
        <Route path='projects' element={<ExperiencePage />} />
        <Route path='contact' element={<ContactPage />} />
      </Routes>
    </Layout>
  );
}

export default App;
