import Header from '../Header';
import Footer from '../Footer';
import HireMe from '../HireMe';

import classes from './styles.module.css'

const Layout = (props) => {
    return (
        <>
            <HireMe />
            <div className={classes.container}>
                <Header />
                <div className={classes.content}>
                    <main>{props.children}</main>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default Layout;