import React from 'react';

import classes from './styles.module.css';

const Footer = (props) => {

    return (
        <footer className={classes.footer}>
            Handmade by <a href="https://room33.dev" target='_blank' rel='noreferrer'>me</a> &copy; AD MMXXII 
        </footer>
    );
};

export default Footer;