import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-recaptcha-google';

import { Icon } from '@iconify/react';

import classes from './styles.module.css';
import userEvent from '@testing-library/user-event';


// class Contact extends React.Component {
//     constructor(props, context) {
//         super(props, context);
//         this.onLoadRecaptcha = this.onLoadRecaptcha.bind(this);
//         this.verifyCallback = this.verifyCallback.bind(this);
//     }
//     componentDidMount() {
//         if (this.captchaDemo) {
//             console.log(" recaptcha started, just a second...")
//             this.captchaDemo.reset();
//             this.captchaDemo.execute();
//         }
//     }
//     onLoadRecaptcha() {
//         if (this.captchaDemo) {
//             this.captchaDemo.reset();
//             this.captchaDemo.execute();
//         }
//     }
//     verifyCallback(recaptchaToken) {
//         // Here you will get the final recaptchaToken!!!  
//         console.log(recaptchaToken, "<= your recaptcha token")
//     }
//     render() {
//         const submitHandler = e => {
//             e.preventDefault();
//             this.verifyCallback()
//             console.log(e)
//         };

//         return (
//             <div className={classes.wrapper}>

//                 <section className={classes.form}>
//                     <h1>Feel free to drop me a line</h1>
//                     <form onSubmit={submitHandler}>
//                         <div className={classes.topRow}>
//                             <label htmlFor="name">Name:</label>
//                             <input type="text" name='name' id='name' placeholder='Name' required className={classes.halfWidth} />
//                             <label htmlFor="email">Email:</label>
//                             <input type="email" name='email' id='email' placeholder='Email' required className={classes.halfWidth} />
//                         </div>
//                         <label htmlFor="subject">Subject:</label>
//                         <input type="text" name='subject' id='subject' placeholder='Subject' />
//                         <label htmlFor="query">Message:</label>
//                         <textarea name="query" id="query" rows="10" placeholder='Message' required></textarea>
//                         <div className={classes.button}><button type='submit'>Send</button></div>
//                         <ReCaptcha
//                             ref={(el) => { this.captchaDemo = el; }}
//                             size="invisible"
//                             render="explicit"
//                             theme="dark"
//                             sitekey="6Lc-soQfAAAAAGLD16foTaE5lYKSZ4H8d-LPeEEd"
//                             onloadCallback={this.onLoadRecaptcha}
//                             verifyCallback={this.verifyCallback}
//                         />
//                     </form>
//                 </section>
//                 <section className={classes.social}>
//                     <p>You can also find me here:</p>
//                     <div className={classes.links}>
//                         <a href="https://www.linkedin.com/in/radekszumski/?lipi=urn%3Ali%3Apage%3Ad_flagship3_feed%3BRcaRHwpCSXyE4POxkxGsyg%3D%3D" target="_blank" rel="noreferrer">
//                             <Icon icon="jam:linkedin-circle" inline={true} height='32' />LinkedIn
//                         </a>
//                         <a href="https://github.com/ThorgalAegirsson" target="_blank" rel="noreferrer">
//                             <Icon icon="jam:github-circle" height="32" inline={true} />GitHub
//                         </a>
//                         <a href="https://twitter.com/slavic_dev" target="_blank" rel="noreferrer">
//                             <Icon icon="jam:twitter-circle" height="32" inline={true} />Twitter
//                         </a>
//                     </div>
//                 </section>

//             </div>
//         );
//     }
// }

const Contact = (props) => {
    const [message, setMessage] = useState('');

    const formRef = useRef();
    const nameRef = useRef();
    const emailRef = useRef();
    const subjectRef = useRef();
    const queryRef = useRef();
    const recaptchaRef = useRef();

    const onChange = value => {
        console.log(`Captcha value: ${value}`);
    };
    const clearForm = () => {
        nameRef.current.value = '';
        emailRef.current.value = '';
        subjectRef.current.value = '';
        queryRef.current.value = '';
    };

    const submitHandler = async e => {
        console.log(formRef)
        e.preventDefault();
        console.log(e)
        const data = new URLSearchParams();
        for (const pair of new FormData(formRef.current)) {
            data.append(pair[0], pair[1]);
        }
        // const recaptchaValue = recaptchaRef.current.getValue();
        // recaptchaRef.current.execute();
        try {
            let res = await fetch('https://radek.website/submit.php', {
                method: "POST",
                // body: JSON.stringify({
                //     name: nameRef.current.value,
                //     email:emailRef.current.value,
                //     subject: subjectRef.current.value,
                //     query: queryRef.current.value
                // })
                body: data
            });
            // let resJSON = await res.json();
            if (res.status === 200) {
                console.log('message sent')
                clearForm();
                setMessage('Message sent!')
                setTimeout(() => {
                    setMessage('');
                }, 5000);
            } else {
                setMessage('Some error occurred...');
            }
        } catch (error) {
            console.error(error)
        }
    };

    return (
        <div className={classes.wrapper}>

            <section className={classes.form}>
                <h1>Feel free to drop me a line</h1>
                <form
                    onSubmit={submitHandler}
                    ref={formRef}
                >
                    <div className={classes.message}>{message ? <p>{message}</p> : null}</div>
                    <div className={classes.topRow}>
                        <label htmlFor="name">Name:</label>
                        <input
                            type="text"
                            name='name'
                            id='name'
                            ref={nameRef}
                            placeholder='Name'
                            required
                            className={classes.halfWidth}
                        />
                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            name='email'
                            id='email'
                            ref={emailRef}
                            placeholder='Email'
                            required
                            className={classes.halfWidth}
                        />
                    </div>
                    <label htmlFor="subject">Subject:</label>
                    <input
                        type="text"
                        name='subject'
                        id='subject'
                        ref={subjectRef}
                        placeholder='Subject'
                    />
                    <label htmlFor="query">Message:</label>
                    <textarea
                        name="query"
                        id="query"
                        ref={queryRef}
                        rows="10"
                        placeholder='Message'
                        required
                    ></textarea>
                    <div className={classes.button}><button type='submit'>Send</button></div>
                    {/* <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6Lc-soQfAAAAAGLD16foTaE5lYKSZ4H8d-LPeEEd"
                        onChange={onChange}
                        theme='dark'
                        size='invisible'
                    /> */}
                </form>
            </section>
            <section className={classes.social}>
                <p>You can also find me here:</p>
                <div className={classes.links}>
                    <a href="https://www.linkedin.com/in/radekszumski/?lipi=urn%3Ali%3Apage%3Ad_flagship3_feed%3BRcaRHwpCSXyE4POxkxGsyg%3D%3D" target="_blank" rel="noreferrer">
                        <Icon icon="jam:linkedin-circle" inline={true} height='32' />LinkedIn
                    </a>
                    <a href="https://github.com/ThorgalAegirsson" target="_blank" rel="noreferrer">
                        <Icon icon="jam:github-circle" height="32" inline={true} />GitHub
                    </a>
                    <a href="https://twitter.com/slavic_dev" target="_blank" rel="noreferrer">
                        <Icon icon="jam:twitter-circle" height="32" inline={true} />Twitter
                    </a>
                </div>
            </section>

        </div>
    );
};

export default Contact;