import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';


import classes from './styles.module.css';

const Header = (props) => {
    
    return (
        <header className={classes.header}>
            <nav>
                <ul>
                    <li>
                        <Link to='/'>Home</Link>
                    </li>
                    <li>
                        <Link to='about'>About me</Link>
                    </li>
                    <li className={classes.logo}>
                        <Icon icon="fa6-solid:laptop-code" inline={true} height='32' />
                    </li>
                    <li>
                        <Link to='projects'>Projects</Link>
                    </li>
                    <li>
                        <Link to='contact'>Contact</Link>
                    </li>
                    
                </ul>
            </nav>
        </header>
    );
};

export default Header;