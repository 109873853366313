import React from 'react';

import { Icon } from '@iconify/react';
// import image from './images/heroImg.svg';

import classes from './styles.module.css';

const Home = (props) => {
    return (
        <>
         {/* <div className={classes.bigWrapper}> */}
            <h1 className={classes.tagline}>Let's build something great together</h1>
            <div className={classes.wrapper}>
                <section className={classes.intro}>
                    <p className={classes.main}>Hello, I’m <span>Radek</span>, freelance web developer based in Dublin.</p>
                    <p>
                        I use modern web technologies to develop apps for Fitbit, Windows and Web.
                    </p>
                </section>
                <img className={classes.heroImg} src="images/heroImg.svg" alt="heroImage" />
            </div>
                {/* </div> */}
                </>
    );
};

export default Home;