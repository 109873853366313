import React from 'react';

import classes from './styles.module.css';

const Image = (props) => {
    
    return (
        <img src={props.image} alt={props.alt} className={classes.screenshot} />
    );
};

export default Image;