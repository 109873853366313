import React from 'react';

import AppSample from '../../AppSample';
import {projects} from '../../../data';

import classes from './styles.module.css';

const Experience = (props) => {
    

    return (
        <div className={classes.wrapper}>
            <h1>Sample open source projects</h1>
            {projects.map(project => <AppSample {...project} key={project.title} />)}
        </div>
    );
};

export default Experience;